input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply border-primary-dark  bg-primary-light border-[2px] border-solid;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

input[type='range']::-moz-range-thumb {
  @apply border-primary-dark  bg-primary-light border-[2px] border-solid;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

input[type='range']::-webkit-slider-runnable-track {
  border: none !important;
}

input[type='range']::-moz-range-track {
  border: none !important;
}

.range-track {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
